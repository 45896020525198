import React, { Component, Fragment } from 'react'
import { Dialog, DialogTitle, DialogActions, Button } from '@material-ui/core';
import Share from '@material-ui/icons/Share';

export default class ModalCompartilhamento extends Component {

    constructor(props) {
        super(props);
        this.state = {
            aberto: false,
        };
    }

    fechar = () => {
        this.setState({ aberto: false })
    }

    handleClick = () => {
        this.setState({ aberto: true });
        this.props.compartilhar();
    }

    copiarParaAreaDeTransferencia = () => {
    	let campo = this.refs.ementa;
    	campo.select();
    	document.execCommand("copy");
    	campo.selectionEnd = campo.selectionStart;
    }
    
   
    
    render() {
        return (
            <Fragment>
                <Button style={{margin: '10px', width: '170px'}} onClick={this.handleClick} variant="contained" color="primary" size="large">
                        <Share /> Compartilhar
                </Button>
                <Dialog open={this.state.aberto} onClose={this.fechar}
                    fullWidth={true}
                    maxWidth={'sm'}>
                    <DialogTitle id="max-width-dialog-title">{this.props.tituloDialogo}</DialogTitle>
                    <textarea 
                        readOnly 
                        ref='ementa' 
                        cols='80' 
                        rows='3'
                        value={this.props.urlCompartilhamento}
                    >
                    </textarea>
                    <DialogActions>
                        <Button onClick={this.copiarParaAreaDeTransferencia} color="primary">
                            Copiar
                        </Button>
                        <Button onClick={this.fechar} color="primary">
                            Fechar
                        </Button>
                    </DialogActions>   
                </Dialog>
            </Fragment>
        )
    }
}
