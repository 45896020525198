import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { Divider, Card, Collapse, IconButton } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

const styles = theme => ({
    formControl: {
        margin: theme.spacing.unit * 3,
    },
    espacamento: { paddingTop: '8px', paddingBottom: '8px' },
});

class TipoJuris extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tiposJurisSelecionados: props.tiposJurisSelecionados,
            orgao: props.orgao,
            expandido: isWidthUp("md", props.width),
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            tiposJurisSelecionados: nextProps.tiposJurisSelecionados,
            orgao: nextProps.orgao
        });
    }

    handleChange = name => event => {
        let valor = event.target.checked;
        let listaTpJuris = this.state.tiposJurisSelecionados;
        if (name === "todos") {
            listaTpJuris.forEach((tj) => {
                tj.checked = valor;
            });
        } else {
            listaTpJuris.forEach((tj) => {
                if (tj.value === name) {
                    tj.checked = valor;
                }
            });
            this.desmarcarTodos(listaTpJuris);
            this.marcarTodos(listaTpJuris);
        }

        this.setState({
            tiposJurisSelecionados: [...listaTpJuris],
        }, () => {
            this.props.atualizaTiposPai(this.state.tiposJurisSelecionados);
        });
    };

    handleChangeOrgao = name => event => {
        let orgao = name;

        this.setState({
            orgao: orgao,
        }, () => {
            this.props.atualizaOrgao(this.state.orgao);
        });

        let listaTpJuris = this.state.tiposJurisSelecionados;
        var i;
        if (orgao === "TST") {
            for (i = 0; i < listaTpJuris.length; i++) {
                listaTpJuris[i].checked = true;
            }
        } else {
            for (i = 0; i < listaTpJuris.length; i++) {
                if (listaTpJuris[i].codigo === 'ACORDAO' || listaTpJuris[i].codigo === 'DESPACHO') {
                    listaTpJuris[i].checked = true;
                } else {
                    listaTpJuris[i].checked = false;
                }
            }
        }

        this.setState({
            tiposJurisSelecionados: [...listaTpJuris],
        }, () => {
            this.props.atualizaTiposPai(this.state.tiposJurisSelecionados);
        });

    };

    divider = (index) => {
        if (index === 0 || index === 2 || index === 5) {
            return (<Divider />);
        }
    }

    desmarcarTodos = (listaTpJuris) => {
        let algumDesmarcado = listaTpJuris.some((tj) => tj.checked === false);
        if (algumDesmarcado) {
            listaTpJuris[0].checked = false;
        }
    }

    marcarTodos = (listaTpJuris) => {
        let todosMarcados = listaTpJuris.every((tj, index) => {
            if (index !== 0) {
                return tj.checked === true;
            }
            else {
                return true;
            }
        });
        if (todosMarcados) {
            listaTpJuris[0].checked = true;
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.tiposJurisSelecionados !== this.props.tiposJurisSelecionados
            || nextState.orgao !== this.state.orgao ||
            nextState.expandido !== this.state.expandido;
    }

    handleExpandClick = () => {
        this.setState({
          expandido: !this.state.expandido,
        });
      };

    render() {
        const { classes } = this.props;

        return (
            <Card className={this.state.expandido ? 'altura-card-padrao' : 'altura-automatica'}>
                <CardHeader
                    title={<Typography variant='h5' style={{ color: 'white' }}>
                                Documentos
                                <IconButton onClick={this.handleExpandClick} alt="Mostrar ou esconder tipos de documento" aria-label='Tipos de documento. Mostrar ou esconder o formulário de tipos de documentos.'>
                                    {this.state.expandido ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </IconButton>
                            </Typography>}
                    disableTypography={true} style={{ backgroundColor: '#4f83cc', height: '32px' }} />

                <Divider />

                <Collapse in={this.state.expandido} timeout="auto" unmountOnExit>
                    <CardContent style={{}} >
                        <FormGroup >
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }} >

                                    <RadioGroup
                                        name="orgao"
                                        style={{ marginTop: '0', paddingTop: '0' }}
                                        className={classes.espacamento}
                                        value={this.state.orgao}
                                    >

                                        <div style={{ display: 'flex' }}>
                                            <FormControlLabel onChange={this.handleChangeOrgao('TST')} value='TST' checked={this.state.orgao === 'TST'} control={<Radio color="primary" />} label="TST" />
                                            <FormControlLabel onChange={this.handleChangeOrgao('CSJT')} value='CSJT' checked={this.state.orgao === 'CSJT'} control={<Radio color="primary" />} label="CSJT" />
                                        </div>

                                    </RadioGroup>
                                    <Divider />

                                    {this.state.tiposJurisSelecionados.map((tipoJurisprudencia, index) => {
                                        return (
                                            <div key={index}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            className={classes.espacamento}
                                                            checked={tipoJurisprudencia.checked}
                                                            onChange={this.handleChange(tipoJurisprudencia.value)}
                                                            value={tipoJurisprudencia.value}
                                                            color="primary" />
                                                    }
                                                    label={tipoJurisprudencia.label}
                                                />
                                                {this.divider(index)}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </FormGroup>
                    </CardContent>
                </Collapse>
            </Card>
        );
    }
}

TipoJuris.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withWidth()(withStyles(styles)(TipoJuris));