import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import ContentClear from '@material-ui/icons/Clear';
import { Button, IconButton } from '@material-ui/core';

const styleNumero = {
    width: '8ch',
    margin: '1%',
};

const styleDigito = {
    width: '2ch',
    margin: '1%',
    fontSize: '22px',
};

const styleAno = {
    width: '4ch',
    margin: '1%',
    fontSize: '17px',
};

const styleOrgao = {
    width: '1ch',
    margin: '1%',
};

const styleTribunal = {
    width: '2ch',
    margin: '1%',
    fontSize: '25px',
};

const styleVara = {
    width: '4ch',
    margin: '1%',
    fontSize: '17px',
};

function _preencheZeros(texto, tamanho) {
    if (texto) {
        var aux = "";
        for (let i = 0; i < tamanho - texto.length; i++) {
            aux += "0";
        }
        return aux + texto;
    }
    return texto;
}

class NumeroDeProcesso extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            classe: props.classe,
            processo: props.processo,
            numero: props.processo.numero,
            digito: props.processo.digito,
            ano: props.processo.ano,
            orgao: props.processo.orgao,
            tribunal: props.processo.tribunal,
            vara: props.processo.vara
        };
        this.pressKey = this.pressKey.bind(this);

        this.refNumero = React.createRef();
        this.refDigito = React.createRef();
        this.refAno = React.createRef();
        this.refOrgao = React.createRef();
        this.refTribunal = React.createRef();
        this.refVara = React.createRef();

    }

    componentWillReceiveProps(props) {
        this.setState({
            classe: props.classe,
            processo: props.processo,
            numero: props.processo.numero,
            digito: props.processo.digito,
            ano: props.processo.ano,
            orgao: props.processo.orgao,
            tribunal: props.processo.tribunal,
            vara: props.processo.vara
        });
    }

    handleNumeroEvent(event) {
        var num = event.target.value;
        this.handleNumero(num);
    }

    async handleNumero(num) {
        if (num.indexOf('-') > 0) {
            let classe;
            let numero;
            [classe, numero] = num.split(' - ');
            var NUMERO_PROCESSO_PATTERN = /([0-9]+)[\.\-]([0-9]+)\.([0-9]+)\.([0-9]+)\.([0-9]+)\.([0-9]+)/;
            var match = NUMERO_PROCESSO_PATTERN.exec(num);
            if (match !== null) {
                await this.setState({
                    classe: numero ? classe : numero,
                    numero: match[1].toString().substring(0, 7),
                    digito: match[2].toString().substring(0, 2),
                    ano: match[3].toString().substring(0, 4),
                    orgao: match[4].toString().substring(0, 1),
                    tribunal: _preencheZeros(match[5], 2).toString().substring(0, 2),
                    vara: _preencheZeros(match[6], 4).toString().substring(0, 4)
                });
                this.atualizaValoresDoCampo(match[1].toString().substring(0, 7), 'numero');
                this.atualizaValoresDoCampo(match[2].toString().substring(0, 2), 'digito');
                this.atualizaValoresDoCampo(match[3].toString().substring(0, 4), 'ano');
                this.atualizaValoresDoCampo(_preencheZeros(match[5], 2).toString().substring(0, 2), 'tribunal');
                this.atualizaValoresDoCampo(_preencheZeros(match[6], 4).toString().substring(0, 4), 'vara');
            } else {
                this.handleApenasNumero(num);
            }
        } else {
            if (num.length === 20) {
                num = num.replace(/(\d)(\d{2})(\d{4})(\d{1})(\d{2})(\d{4})$/, "$1-$2.$3.$4.$5.$6");
                let NUMERO_PROCESSO_PATTERN = /([0-9]+)[\.\-]([0-9]+)\.([0-9]+)\.([0-9]+)\.([0-9]+)\.([0-9]+)/;
                let match = NUMERO_PROCESSO_PATTERN.exec(num);

                if (match !== null) {
                    this.setState({
                        numero: match[1].toString().substring(0, 7),
                        digito: match[2].toString().substring(0, 2),
                        ano: match[3].toString().substring(0, 4),
                        orgao: match[4].toString().substring(0, 1),
                        tribunal: _preencheZeros(match[5], 2).toString().substring(0, 2),
                        vara: _preencheZeros(match[6], 4).toString().substring(0, 4)
                    });
                    this.atualizaValoresDoCampo(match[1].toString().substring(0, 7), 'numero');
                    this.atualizaValoresDoCampo(match[2].toString().substring(0, 2), 'digito');
                    this.atualizaValoresDoCampo(match[3].toString().substring(0, 4), 'ano');
                    this.atualizaValoresDoCampo(_preencheZeros(match[5], 2).toString().substring(0, 2), 'tribunal');
                    this.atualizaValoresDoCampo(_preencheZeros(match[6], 4).toString().substring(0, 4), 'vara');
                }
                return;
            } else if (num.length > 6) {
                num = num.toString().substring(0, 7);
                this.refDigito.current.focus();
            }
            this.setState({ numero: num.replace(/\D+/g, '').trim() });
            this.atualizaValoresDoCampo(num, 'numero');
        }
    }

    atualizaValoresDoCampo = (valor, campoInterno) => {
        this.props.atualizaValorDosCamposDePesquisaTextual('processo', valor, campoInterno);
    }

    handleApenasNumero(num) {
        var numeroSeparado = num.split('-');
        this.setState({ numero: numeroSeparado[0].trim().toString().substring(0, 7) });
        this.atualizaValoresDoCampo(num, 'numero');

        if (numeroSeparado[1]) {
            var restoNumeroUnico = numeroSeparado[1].split('.');

            this.setState(restoNumeroUnico[0] ? { digito: restoNumeroUnico[0].toString().substring(0, 2) } : { digito: '' });
            this.setState(restoNumeroUnico[1] ? { ano: restoNumeroUnico[1].toString().substring(0, 4) } : { ano: '' });
            this.setState(restoNumeroUnico[2] ? { orgao: restoNumeroUnico[2].toString().substring(0, 1) } : { orgao: '' });
            this.setState(restoNumeroUnico[3] ? { tribunal: restoNumeroUnico[3].toString().substring(0, 2) } : { tribunal: '' });
            this.setState(restoNumeroUnico[4] ? { vara: restoNumeroUnico[4].toString().substring(0, 4) } : { vara: '' });
        }
    }

    handleDigito(event) {
        var dig = event.target.value.replace(/\D+/g, '').trim();

        if (dig.length > 1) {
            dig = dig.toString().substring(0, 2);
            this.refAno.current.focus();
        } else if (dig.length === 0) {
            this.refNumero.current.focus();
        }
        this.setState({ digito: dig });
        console.log(this.state);
        this.atualizaValoresDoCampo(dig, 'digito');
    }

    handleAno(event) {
        var ano = event.target.value.replace(/\D+/g, '').trim();
        if (ano.length > 3) {
            ano = ano.toString().substring(0, 4);
            this.refTribunal.current.focus();
        } else if (ano.length === 0) {
            this.refDigito.current.focus();
        }
        this.setState({ ano: ano });
        this.atualizaValoresDoCampo(ano, 'ano');
    }

    handleOrgao(event) {
        var org = event.target.value.replace(/\D+/g, '').trim();
        if (org.length === 0) {
            this.refAno.current.focus();
        } else {
            org = org.toString().substring(0, 1);
            this.refTribunal.current.focus();
        }
        this.setState({ orgao: org });
        this.atualizaValoresDoCampo(org, 'orgao');
    }

    handleTribunal(event) {
        var reg = event.target.value.replace(/\D+/g, '').trim();
        if (reg.length > 1) {
            reg = reg.toString().substring(0, 2);
            this.refVara.current.focus();
        } else if (reg.length === 0) {
            this.refAno.current.focus();
        }
        this.setState({ tribunal: reg });
        this.atualizaValoresDoCampo(reg, 'tribunal');        
    }

    handleVara(event) {
        var vara = event.target.value.replace(/\D+/g, '').trim();
        if (vara.length > 4) {
            vara = vara.toString().substring(0, 5);
            return;
        } else if (vara.length === 0) {
            this.refTribunal.current.focus();
        }
        this.setState({ vara: vara });
        this.atualizaValoresDoCampo(vara, 'vara');
    }

    compararNumero(a, b) {
        this.handleNumero(a);
        let nb = new NumeroDeProcesso({});
        nb.handleNumero(b);
        for (let parte in a.state) {
            let cmp = this.compararParte(this.state[parte], nb.state[parte]);
            if (cmp === 0) {
                continue;
            }
            return cmp;
        }
    }

    compararParte(a, b) {
        if (a && b === undefined) {
            return 1;
        } else if (a === undefined && b) {
            return -1;
        } else {
            return this.compare(a, b);
        }
    }

    compare(a, b) {
        if (typeof (a) === 'number') {
            return a - b;
        }

        const ax = [];
        const bx = [];

        a.replace(/(\d+)|(\D+)/g, (_, $1, $2) => {
            ax.push([$1 || Infinity, $2 || '']);
        });
        b.replace(/(\d+)|(\D+)/g, (_, $1, $2) => {
            bx.push([$1 || Infinity, $2 || '']);
        });

        while (ax.length && bx.length) {
            const an = ax.shift();
            const bn = bx.shift();
            const nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1]);
            if (nn) {
                return nn;
            }
        }

        return ax.length - bx.length;
    }

    getNumeroDeProcesso = () => {
        return {
            numero: this.state.numero,
            digito: this.state.digito,
            ano: this.state.ano,
            orgao: this.state.orgao,
            tribunal: this.state.tribunal,
            vara: this.state.vara
        };
    }

    qualquerCampoPreenchido = () => {
        return this.state.numero || this.state.digito || this.state.ano || this.state.orgao || this.state.tribunal || this.state.vara;
    }

    limpar = () => {
        this.setState({
            numero: "",
            digito: "",
            ano: "",
            orgao: "5",
            tribunal: "",
            vara: ""
        });
        this.atualizaValoresDoCampo("", 'numero');
        this.atualizaValoresDoCampo("", 'digito');
        this.atualizaValoresDoCampo("", 'ano');
        this.atualizaValoresDoCampo("", 'tribunal');
        this.atualizaValoresDoCampo("", 'vara');
    }

    componentDidMount() {
        document.querySelector("#numero").style.textAlign = 'right';
    }

    pressKey(ev) {
        if (ev && ev.key.indexOf('Enter') > -1) {
            this.props.pressEnter && this.props.pressEnter();
        }
    }

    render() {
        return (
            <div style={{ display: 'flex', 
                          flexDirection: 'row', 
                          alignItems: 'flex-end', 
                          paddingTop: '15px' }} 
                title={'Pesquisar Processo'}>
                <TextField
                    id="numero"
                    name="numero"
                    label="Processo"
                    placeholder="Número"
                    aria-label="Número do processo"
                    style={styleNumero}
                    value={this.state.numero}
                    onChange={this.handleNumeroEvent.bind(this)}
                    ref="inputNum"
                    rows={1}
                    disabled={this.props.isDisabled}
                    onKeyPress={this.pressKey}
                    inputRef={this.refNumero}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                -
                <TextField
                    id="digito"
                    name="digito"
                    placeholder="DV"
                    aria-label="DV do processo"
                    style={styleDigito}
                    value={this.state.digito}
                    onChange={this.handleDigito.bind(this)}
                    ref="inputDigito"
                    rows={1}
                    disabled={this.props.isDisabled}
                    onKeyPress={this.pressKey}
                    inputRef={this.refDigito}
                />
                .
                <TextField
                    id="ano"
                    name="ano"
                    placeholder="Ano"
                    aria-label="Ano do processo"
                    style={styleAno}
                    value={this.state.ano}
                    onChange={this.handleAno.bind(this)}
                    ref="inputAno"
                    rows={1}
                    disabled={this.props.isDisabled}
                    onKeyPress={this.pressKey}
                    inputRef={this.refAno}
                />
                .
                <TextField
                    id="orgao"
                    style={styleOrgao}
                    value={this.state.orgao}
                    onChange={this.handleOrgao.bind(this)}
                    ref="inputOrgao"
                    rows={1}
                    disabled={this.props.isDisabled}
                    onKeyPress={this.pressKey}
                    inputRef={this.refOrgao}
                    aria-label="Órgão"
                />
                .
                <TextField
                    id="tribunal"
                    placeholder="TRT"
                    aria-label="TRT"
                    style={styleTribunal}
                    value={this.state.tribunal}
                    onChange={this.handleTribunal.bind(this)}
                    ref="inputTribunal"
                    rows={1}
                    disabled={this.props.isDisabled}
                    onKeyPress={this.pressKey}
                    inputRef={this.refTribunal}
                />
                .
                <TextField
                    id="vara"
                    placeholder="Vara"
                    aria-label="Vara"
                    style={styleVara}
                    value={this.state.vara}
                    onChange={this.handleVara.bind(this)}
                    ref="inputVara"
                    rows={1}
                    disabled={this.props.isDisabled}
                    onKeyPress={this.pressKey}
                    inputRef={this.refVara}
                />
                <IconButton title="Limpar número do processo" aria-label="Limpar número do processo" onClick={this.limpar} color="secondary">
                    <ContentClear style={{ cursor: 'pointer', width: '20px', height: '20px' }} />
                </IconButton>
            </div>
        );
    }
}

NumeroDeProcesso.defaultProps = {
    numero: "",
    digito: "",
    ano: "",
    orgao: "5",
    tribunal: "",
    vara: "",
    isDisabled: false,
    callback: function () { }
};

NumeroDeProcesso.propTypes = {
    numero: PropTypes.string,
    digito: PropTypes.string,
    ano: PropTypes.string,
    orgao: PropTypes.string,
    tribunal: PropTypes.string,
    vara: PropTypes.string,
    isDisabled: PropTypes.bool,
    callback: PropTypes.func
};

export default NumeroDeProcesso;