import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogActions, Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';

const styles = theme => ({
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    }
});

class ModalAjuda extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            aberto: false,
        };
    }
    
    componentDidMount() {
    	if (!(localStorage.getItem('depoisPrimeiroAcesso') === 'true')) {
    		localStorage.setItem('depoisPrimeiroAcesso', 'true');
    		this.setState({
    			aberto: true
    		});
    	}
    	
    	
    }

    fechar = () => {
        this.setState({ aberto: false })
    }

    handleClick = () => {
        this.setState({ aberto: true })
    }


    render() {
        const { classes } = this.props;
        return (
            <Fragment>

                <IconButton onClick={this.handleClick} className={classes.menuButton} color="inherit" aria-label="Menu">
                    <HelpIcon />
                </IconButton>
                <Dialog open={this.state.aberto} onClose={this.fechar}
                    fullWidth={true}
                    maxWidth={'md'}>
                    <DialogTitle id="max-width-dialog-title">SISTEMA DE PESQUISA DE JURISPRUDÊNCIA</DialogTitle>
                    <div style={{margin: "20px"}}>
                        <div style={{marginLeft: "5px", marginTop: "-20px"}}>DICAS RÁPIDAS</div>
                        <ul>
                            <li>Não utilize conectores (e, adj, prox, etc) nem caracteres especiais ($)</li>
                            <li>Os campos disponíveis para a pesquisa livre podem ser usados ao mesmo tempo. <br/>Preencha-os conforme a seguinte divisão:
                                <br/> - <b>Contendo as palavras (e)</b> = todas as palavras e expressões que você deseja pesquisar;
                                <br/> - <b>Qualquer das palavras (ou)</b> = sinônimos ou expressões equivalentes que servem para a sua pesquisa;
                                <br/> - <b>Sem conter as palavras (não)</b> = palavras e expressões que você deseja excluir da pesquisa.
                            </li>
                            <li>Todos os campos admitem o uso de <b>aspas</b> e elas devem ser empregadas para <b>pesquisas exatas</b> de expressões ou palavras compostas (Ex.: “adicional de periculosidade”).</li>
                            <li><b>Clique</b> sobre o <b>número do processo</b> para que seja exibido o inteiro teor do acórdão/decisão monocrática com os <b>argumentos</b> de pesquisa <b>realçados</b>.</li>
                            <li>Caso o resultado apresentado não esteja a contento, experimente o critério da <b>relevância</b> (botão disponível logo acima do resultado da pesquisa). O resultado será ordenado pela maior incidência dos argumentos de pesquisa e com a maior proximidade entre eles</li>
                            <li>Utilize os <b>filtros</b> para refinar a sua pesquisa (Ex: o campo “Processo” pode ser empregado para restringir o resultado a processos com “Ano”= 2017 e “TRT”= 15).</li>
                            <li>O link “Ementa para citação” apresenta a ementa e os dados do processo para transcrição. Clique em “Copiar tudo” e cole no editor em uso</li>
                            <li>Selecione o <i>checkbox</i> existente ao lado esquerdo de cada documento apresentado para exportá-los para um arquivo em formato <i>pdf</i>. Isso pode ser feito em um ou mais documentos conjuntamente</li>
                            <li>A partir de agora é possível pesquisar no dispositivo dos acórdãos (isto posto) e consultar súmulas, OJs e PNs</li>
                            <li>Para uma nova pesquisa, role a tela até o topo ou clique no ícone “home”, no canto superior direito</li>
                        </ul>
                    </div>
                    <DialogActions>
                        <Button onClick={this.fechar} color="primary">
                            Fechar
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        )
    }
}
export default withStyles(styles)(ModalAjuda);