import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import configuration from 'config-easy';
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { MuiThemeProvider, createMuiTheme, CircularProgress } from "@material-ui/core";

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: {
      light: "#b71c1c",
      main: "#01579b",
      dark: "#bbdefb",
      contrastText: "#fff"
    },
    secondary: {
      light: "#f05545",
      main: "#b71c1c",
      dark: "#7f0000",
      contrastText: "#fff"
    }
  },
  overrides: {
    MuiFormLabel: {
      root: {
        fontWeight: "bold"
      },
      focused: {
        "&$focused": {
          color: "#01579b",
          fontWeight: "bold"
        }
      }
    }
  }
});

const main = async () => {
  ReactDOM.render(
    <MuiThemeProvider theme={theme}>
      <center>
        <CircularProgress style={{ marginTop: "20px" }} />
      </center>
    </MuiThemeProvider>,
    document.getElementById("root")
  );
  
  let response = {"success": false};

  try {
    response = await configuration.config();
  } catch (e) {
    console.error(e);
  }

  let component = <h2>Problemas com as configurações da aplicação. Favor contatar o suporte de TI.</h2>;
  if (response.success) {
    component = <App />;
  }

  ReactDOM.render(
    <MuiThemeProvider theme={theme}>
      {component}
    </MuiThemeProvider>,
    document.getElementById("root")
  );

}

main();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
