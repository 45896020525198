import React from "react";
import DropdownTreeSelect from "react-dropdown-tree-select";
import FormLabel from "@material-ui/core/FormLabel";
import "react-dropdown-tree-select/dist/styles.css";
import "./dropdown-material.css";

function DropdownTreeSelectMaterial({ label, data, selected, ...props }) {
  function getUpdatedData(data) {
    if (!selected) {
      return data;
    }

    return data.map((node) => {
      let resultNode = node;

      if (selected.includes(node.value)) {
        resultNode.checked = true;
      } else {
        resultNode.checked = false;
      }

      if (node.children) {
        resultNode.children = getUpdatedData(node.children);
      }

      if (resultNode.children) {
        const checkedChildrenCount = resultNode.children.filter(
          (child) => child.checked
        ).length;
        const childrenCount = resultNode.children.length;
        resultNode.expanded =
          checkedChildrenCount > 0 && checkedChildrenCount < childrenCount;
      }

      return resultNode;
    });
  }

  function ajustaLarguraCombo () {
    setTimeout(function(){
      document.getElementsByClassName("dropdown-content")[0].style.width = (document.getElementById("idOrgaoJudic").offsetWidth - 10) + "px";
    }, 10);
  }

  return (
    <div style={{ paddingTop: "1rem" }}>
      <FormLabel aria-hidden={true} style={{ fontSize: "0.7rem" }}>{label}</FormLabel>
      <DropdownTreeSelect
        {...props}
        data={getUpdatedData(data)}
        className="mdl"
        onFocus={ajustaLarguraCombo}
      />
    </div>
  );
}

export default DropdownTreeSelectMaterial;
