import { Button, Divider, Hidden } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Clear from '@material-ui/icons/Clear';
import HomeIcon from '@material-ui/icons/Home';
import Search from '@material-ui/icons/Search';
import configuration from 'config-easy';
import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import ModalAjuda from './ModalAjuda.js';
import ModalCompartilhamento from './ModalCompartilhamento.js';
import PesquisaAvancada from './PesquisaAvancada.js';
import PesquisaTextual from './PesquisaTextual.js';
import TipoJuris from './TipoJuris.js';
import './css/App.css';
import logo_tst from './img/logo_tst2.png';
const AgregadorResultado = React.lazy(() => import('./AgregadorResultado'));

var isDataSuportada = null;

const styles = theme => ({
	root: {
		flexGrow: 1,
		width: '100%',
	},
	tableRoot: {
		display: "inline-block"
	},
	grow: {
		flexGrow: 1,
	},
	menuButton: {
		marginLeft: -12,
		marginRight: 20,
	},
	container: {
		display: 'flex',
		flexDirection: 'row',
	},
	textField: {
		width: 200,
	},
	dense: {
		marginTop: 19,
	},
	menu: {
		width: 200,
	},
	expand: {
		transform: 'rotate(0deg)',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
		marginLeft: 'auto',
		[theme.breakpoints.up('sm')]: {
			marginRight: -8,
		},
	},
	expandOpen: {
		transform: 'rotate(180deg)',
	},
	progress: {
		margin: theme.spacing.unit * 2,
		position: 'absolute',
		top: '45%',
		left: '45%'
	}
});

const searchParams = new URLSearchParams(window.location.search);
let isCsjt = searchParams.get('p') === 'csjt';

const listaTiposJurisprudencia = [
	{ codigo: 'TODOS', value: "todos", codMin: "", checked: !isCsjt, label: "Todos", qtdRegistros: 0, },
	{ codigo: 'ACORDAO', value: "acordaos", codMin: "", checked: true, label: "Acórdãos", qtdRegistros: 0, },
	{ codigo: 'DESPACHO', value: "despachos", codMin: "", checked: true, label: "Decisões Monocráticas", qtdRegistros: 0, },
	{ codigo: 'SUM', value: "sumulas", codMin: "", checked: !isCsjt, label: "Súmulas", qtdRegistros: 0, },
	{ codigo: 'PN', value: "precedentes", codMin: "", checked: !isCsjt, label: "Precedentes Normativos", qtdRegistros: 0, },
	{ codigo: 'OJ', value: "ojs", codMin: "", checked: !isCsjt, label: "Orientações Jurisprudenciais", qtdRegistros: 0, },
	{ codigo: 'DESPGP', value: "despGP", codMin: "GP", checked: !isCsjt, label: "Decisões da Presidência", qtdRegistros: 0, },
	{ codigo: 'DESPGVP', value: "despGVP", codMin: "GVP", checked: !isCsjt, label: "Decisões da Vice Presidência", qtdRegistros: 0, },
	{ codigo: 'DESPGCG', value: "despGCG", codMin: "GCG", checked: !isCsjt, label: "Decisões da Corregedoria Geral", qtdRegistros: 0, }
];

const resultadoVazio = {
	tiposJurisAgregador: [],
	tipoJurisAgregadorSelecionado: null,
	resultados: [],
	agregacoes: [],
	mensagemConsulta: "",
	pesquisando: false,
};

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			indicePaginaAtual: 0,
			quantidadeRegistros: 20,
			totalRegistros: 0,
			operadorOu: '',
			operadorE: '',
			operadorExpressaoExata: '',
			operadorNaoContem: '',
			ementa: '',
			dispositivo: '',
			processo: {
				numero: '',
				ano: '',
				digito: '',
				orgao: '5',
				tribunal: '',
				vara: ''
			},
			orgaosJudicantesSelecionados: [],
			ministrosSelecionados: [],
			convocadosSelecionados: [],
			classesProcessuaisSelecionadas: [],
			indicadoresSelecionados: [],
			assuntosSelecionados: [],
			tiposJurisSelecionados: JSON.parse(JSON.stringify(listaTiposJurisprudencia)),
			orgao: isCsjt ? 'CSJT' : 'TST',
			publicacaoInicial: this.getValorVazioParaCampoDeData(),
			publicacaoFinal: this.getValorVazioParaCampoDeData(),
			julgamentoInicial: this.getValorVazioParaCampoDeData(),
			julgamentoFinal: this.getValorVazioParaCampoDeData(),
			urlCompartilhamento: '',
			indLeiaMais: localStorage.indLeiaMais !== undefined ? eval(localStorage.indLeiaMais) : false,
			...resultadoVazio,
		};
	}

	scrollAnimado = (posAtual, posFinal, movimento) => {
		if (!movimento) {
			movimento = 150;
		}
		let tamanhoAppBar = 64;
		let proxPos;
		let finaliza = false;
		if (posFinal > posAtual) {
			proxPos = posAtual + movimento;
			if (proxPos >= posFinal) {
				proxPos = posFinal - tamanhoAppBar;
				finaliza = true;
			}
		} else {
			proxPos = posAtual - movimento;
			if (proxPos <= posFinal) {
				proxPos = posFinal - tamanhoAppBar;
				finaliza = true;
			}
		}
		window.scrollTo(0, proxPos);
		if (finaliza) {
			return;
		} else {
			setTimeout(() => {
				this.scrollAnimado(proxPos, posFinal, movimento)
			}, 100);
		}

	}

	// callback function para tratamento das modificações em dados da pesquisa textual e dos filtros
	atualizaValorDosCamposDePesquisaTextual = (campo, valor, campoInterno) => {
		if (campoInterno) {
			let variavel = this.state[campo];
			variavel[campoInterno] = valor;
			this.setState({
				[campo]: variavel,
				...resultadoVazio,
			});
		} else {
			this.setState({
				[campo]: valor,
				...resultadoVazio,
			});
		}
	}

	// callback function para tratamento do clique em TipoJuris.js
	atualizaTipos = listaTpJuris => {
		this.setState({
			tiposJurisSelecionados: listaTpJuris,
			...resultadoVazio,
		});
	}

	// callback function para tratamento do clique em TipoJuris.js
	atualizaOrgao = orgao => {
		this.setState({
			orgao: orgao,
			...resultadoVazio,
		});
	}

	// Monta lista de tipos para o JSON do backend
	atualizaListaTipoJuris = (tiposJurisParaPesquisa) => {
		let tiposJuris = tiposJurisParaPesquisa.filter(a => a.checked === true &&
			a.codigo !== 'TODOS')
			.map(a => a.codigo);
		return tiposJuris;
	}

	// callback function para tratamento do clique em AgregadorResultado.js
	atualizaTipoJurisAgregadorSelecionado = (page, rowsPerPage, tipoJurisAgregadorSelecionado, isMudancaManual) => {
		let tpJurisAgregInterno = this.state.tiposJurisSelecionados.filter(a => a.checked === true);
		//Quando utilizar somente o de súmulas foi pedido para não paginar, então foi colocada a quantidade de páginas = 1000
		if ( tpJurisAgregInterno.length == 1 && (tpJurisAgregInterno[0].codigo == 'SUM' || tpJurisAgregInterno[0].codigo == 'OJ')) {
			if ( !isMudancaManual ) {
				rowsPerPage = 1000
			} else {
				this.setState({
					rowsPerPageAntes: rowsPerPage
				});
			}
		} else {
			if ( !rowsPerPage ) {
				if ( this.state.rowsPerPageAntes ) {
					rowsPerPage = this.state.rowsPerPageAntes
				} else {
					rowsPerPage = 20
				}
			} else {
				this.setState({
					rowsPerPageAntes: rowsPerPage
				});
			}
		}
		this.setState({
			tipoJurisAgregadorSelecionado: tipoJurisAgregadorSelecionado,
		}, () => {
			this.pesquisar(page, rowsPerPage);
		});
	}

	// callback function para limpar a mensagem de erro ao fechá-la
	apagaMensagem = () => {
		this.setState({
			mensagemConsulta: "",
		})
	}

	determinaQuantidadeRegistrosPorAgregacao(codigoTipoJurisprudencia) {
		let retorno = 0;
		console.log("this.state.agregacoes: ", this.state.agregacoes);
		if (this.state.agregacoes &&
			this.state.agregacoes.length > 0) {
			if (codigoTipoJurisprudencia === 'TODOS') {
				retorno = this.state.totalRegistros;
			} else if (this.state.agregacoes[0].mapaTermoQuantidade[codigoTipoJurisprudencia] !== undefined) {
				if (codigoTipoJurisprudencia === "OJ" ||
					codigoTipoJurisprudencia === "OJT") {
					retorno = (this.state.agregacoes[0].mapaTermoQuantidade['OJ'] ?
						       this.state.agregacoes[0].mapaTermoQuantidade['OJ'] :
						       0)
						    + (this.state.agregacoes[0].mapaTermoQuantidade['OJT'] ?
							   this.state.agregacoes[0].mapaTermoQuantidade['OJT'] :
							   0);
				} else {
					retorno = (this.state.agregacoes[0].mapaTermoQuantidade[codigoTipoJurisprudencia] ?
						       this.state.agregacoes[0].mapaTermoQuantidade[codigoTipoJurisprudencia] :
						       0);
				}
			}

			if ((codigoTipoJurisprudencia === "DESPGP" ||
				 codigoTipoJurisprudencia === "DESPGVP" ||
				 codigoTipoJurisprudencia === "DESPGCG") &&
				this.state.agregacoes[0].respostasSubAgregacoes['DESPACHO'] !== undefined &&
				this.state.agregacoes[0].respostasSubAgregacoes['DESPACHO'][0] !== undefined &&
				this.state.agregacoes[0].respostasSubAgregacoes['DESPACHO'][0].mapaTermoQuantidade[codigoTipoJurisprudencia.substr(4)] !== undefined) {
				retorno = this.state.agregacoes[0].respostasSubAgregacoes['DESPACHO'][0].mapaTermoQuantidade[codigoTipoJurisprudencia.substr(4)];
			}
		}

		return retorno;
	}

	determinaMensagem = (response, qtdRegistros) => {
		let mensagem = "";
		if (!response.ok) {
			if (response.status === 410) {
				mensagem = "Índice não encontrado no servidor";
			} else if (response.status === 500) {
				mensagem = "Erro interno no servidor de pesquisa";
			} else {
				mensagem = "Servidor de pesquisa não está respondendo";
			}
		} else {
			if (qtdRegistros === 0) {
				mensagem = "Nenhum registro encontrado com os parâmetros informados";
			}
		}

		return mensagem;
	}

	existeListaAgregadoresInicializada() {
		return this.state.tiposJurisAgregador !== null &&
			   this.state.tiposJurisAgregador !== undefined &&
			   this.state.tiposJurisAgregador.length > 0;
	}

	existeTipoJurisAgregadorSelecionado() {
		return this.state.tipoJurisAgregadorSelecionado !== null &&
			   this.state.tipoJurisAgregadorSelecionado !== undefined &&
			   this.state.tipoJurisAgregadorSelecionado.codigo !== null &&
			   this.state.tipoJurisAgregadorSelecionado.codigo !== undefined;
	}

	determinaTiposJurisAgregacoes() {
		let tiposJuris = [];
		// Se existem agregadores (this.state.tiposJurisAgregador é um array com elementos) 
		// significa que uma consulta já foi feita na tela
		if (this.existeListaAgregadoresInicializada()) {
			if (this.existeTipoJurisAgregadorSelecionado()) {
				// Monta lista de tipos de jurisprudência para o JSON apenas com o agregador selecionado
				tiposJuris.push(this.state.tipoJurisAgregadorSelecionado.codigo);
			} else {
				// Monta lista de tipos de jurisprudência para o JSON apenas com o primeiro agregador da lista
				this.setState({
					tipoJurisAgregadorSelecionado: this.state.tiposJurisAgregador[0],
				});
				tiposJuris.push(this.state.tiposJurisAgregador[0].codigo);
			}
			// Se a agregação selecionada é o "Todos" gera a lista com as demais, pois, o 
			// "Todos" em si não é um tipo de jurisprudência a ser passado como parâmetro
			// no JSON a ser gerado para o backend
			if (tiposJuris[0] === 'TODOS') {
				tiposJuris = this.atualizaListaTipoJuris(this.state.tiposJurisSelecionados);
			}
		} else {
			// A lista dos agregadores (this.state.tiposJurisAgregador) ser inicializada 
			// com apenas os tipos selecionados e sem o "todos"
			let tpJurisAgregInterno = this.state.tiposJurisSelecionados.filter(a => a.checked === true);

			if (tpJurisAgregInterno.length > 0) {
				// Inclui o "Todos" no final das agregações - BANJUR-257
				if (tpJurisAgregInterno[0].codigo === 'TODOS') {
					tpJurisAgregInterno = tpJurisAgregInterno.filter(a => a.codigo !== 'TODOS');
				}
				if (tpJurisAgregInterno.length > 1) {
					tpJurisAgregInterno.push(listaTiposJurisprudencia[0]);
				}
				this.setState({
					tiposJurisAgregador: tpJurisAgregInterno,
				});
				tiposJuris = this.atualizaListaTipoJuris(tpJurisAgregInterno);
			}	
		}

		return tiposJuris;
	}

	getArrayDeObjAPartirDoArrayDeIds = (nomeCombo, ids) => {
		if ( !ids || ids.length == 0) {
			return [];
		}
		// Obtendo o JSON armazenado no localStorage e convertendo para array de objetos
		let registros = JSON.parse(localStorage[nomeCombo]);

		let objs = [];
		// Array para armazenar os objetos correspondentes aos ids

		// Percorrendo o array de ids
		ids.forEach(item => {
			// Procurando o objeto correspondente ao id
			let itemId = item.id || item;
			let obj = registros.find(registro => registro.id === itemId);
			// Se encontrar, adiciona o atributo 'obj' ao array objs
			if (obj) {
				objs.push(obj.obj);
			}
		});

		console.log(objs);

		return objs

	}

	pesquisar = (pagina, registrosPorPagina) => {
		this.setState({
			pesquisando: true,
		});

		let indicePaginaAtual = pagina ? pagina : 0;
		let quantidadeRegistros = registrosPorPagina ? registrosPorPagina : this.state.quantidadeRegistros;

		// O objetivo destas 3 linhas é limitar a 1 registro de paginação a primeira execução do botão
		// "pesquisar" para ser feita a "contagem" das quantidades por "Tipo de Jurisprudência"
		// Mais abaixo (após o setState em "response" e após esta "contagem") é feita uma chamada recursiva 
		// para esta mesma rotina com o agregador "default" selecionado e o if abaixo falhará e a rotina
		// será executada novamente com a paginação definida pelo usuário em "quantidadeRegistros"
		let quantidadeRegistrosOriginal = quantidadeRegistros;
		if (!this.existeTipoJurisAgregadorSelecionado()) {
			quantidadeRegistros = 1;
		}

		// Monta a lista de agregações de acordo com a regra definida pelo usuário
		// em função dos "Tipos de Jurisprudência" selecionados.
		// Atualmente (em 20/03/2019) coloca o "Todos" ao final da lista
		let tiposJuris = this.determinaTiposJurisAgregacoes();

		let orderBy = "";
		//console.log("this.state.ordenacao", this.state.ordenacao);
		//console.log("this.state.tipoJurisAgregadorSelecionado", this.state.tipoJurisAgregadorSelecionado);
		if (this.existeTipoJurisAgregadorSelecionado()) {
			let tipoJurisprudencia = this.state.tipoJurisAgregadorSelecionado;
			if (tipoJurisprudencia.codigo === "SUM" || 
				tipoJurisprudencia.codigo === "OJ" || 
				tipoJurisprudencia.codigo === "PN") {
				if (!this.state.ordenacao) {
                    orderBy = "numero";
				} else {
					orderBy = this.state.ordenacao;
				}	
			} else {
				if (this.state.ordenacao && 
					this.state.ordenacao === "numero") {
					orderBy = "data";
				} else {
					orderBy = this.state.ordenacao;
				}
			}
		} else {
			if (this.state.ordenacao && 
				this.state.ordenacao === "numero") {
				orderBy = "data";
			} else {
				orderBy = this.state.ordenacao;
			}
	    }
		
		let json = {
			'ou': this.state.operadorOu,
			'e': this.state.operadorE,
			'termoExato': this.state.operadorExpressaoExata,
			'naoContem': this.state.operadorNaoContem,
			'ementa': this.state.ementa,
			'dispositivo': this.state.dispositivo,
			'numeracaoUnica': this.state.processo,
			'orgaosJudicantes': this.getArrayDeObjAPartirDoArrayDeIds('orgaosJudicantes', this.state.orgaosJudicantesSelecionados),
			'ministros': this.getArrayDeObjAPartirDoArrayDeIds('ministros', this.state.ministrosSelecionados),
			'convocados': this.getArrayDeObjAPartirDoArrayDeIds('convocados', this.state.convocadosSelecionados),
			'classesProcessuais': this.getArrayDeObjAPartirDoArrayDeIds('classesProcessuais', this.state.classesProcessuaisSelecionadas),
			'indicadores': this.getArrayDeObjAPartirDoArrayDeIds('indicadores', this.state.indicadoresSelecionados),
			'assuntos': this.state.assuntosSelecionados,
			'tipos': tiposJuris,
			'orgao': this.state.orgao,
			'publicacaoInicial': this.state.publicacaoInicial,
			'publicacaoFinal': this.state.publicacaoFinal,
			'julgamentoInicial': this.state.julgamentoInicial,
			'julgamentoFinal': this.state.julgamentoFinal,
			'ordenacao': orderBy,
		};

		console.log('indicePaginaAtual = ', indicePaginaAtual);
		console.log('quantidadeRegistros = ', quantidadeRegistros);
		console.log('JSON GERADO = ', JSON.stringify(json));

		fetch(`${configuration.get('base_url')}/rest/pesquisa-textual/` + ((indicePaginaAtual * quantidadeRegistros) + 1) + '/' + quantidadeRegistros + "?a=" + Math.random(), {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
            body: JSON.stringify(json)
		})
			.then(response => {
				response.json().then(data => {
					this.setState({
						resultados: JSON.parse(JSON.stringify((data.registros != null ? data.registros : []))),
						agregacoes: JSON.parse(JSON.stringify((data.agregacoes != null ? data.agregacoes : []))),
						mensagemConsulta: this.determinaMensagem(response, data.totalRegistros),
						totalRegistros: data.totalRegistros,
						pesquisando: false,
						ordenacao : orderBy,
					}, () => {
						if (this.existeListaAgregadoresInicializada()) {
							if (!this.existeTipoJurisAgregadorSelecionado()) {
								let tpJurisAgregadorInterno = this.state.tiposJurisAgregador.map(tipoJurisprudencia => {
									tipoJurisprudencia.qtdRegistros = this.determinaQuantidadeRegistrosPorAgregacao(tipoJurisprudencia.codigo);
									return tipoJurisprudencia;
								});
								this.setState({
									tiposJurisAgregador: tpJurisAgregadorInterno,
								}, () => {
									// Determina os tipos com qtdRegistros > 0 (tem algum resultado)
									// e coloca o primeiro deles como default chamando a rotina de 
									// "seleção" da agregação
									tpJurisAgregadorInterno = tpJurisAgregadorInterno.filter(
										a => a.qtdRegistros > 0
									)
									this.atualizaTipoJurisAgregadorSelecionado(null, null, tpJurisAgregadorInterno[0]);
								});
							}
					    }
					});
					window.scrollTo(0, document.getElementById('main').offsetTop - 81);
					if ( document.getElementsByClassName("classeAbasResultado") && document.getElementsByClassName("classeAbasResultado").length > 0)
						document.getElementsByClassName("classeAbasResultado")[0].focus();

					//console.log("Resultado registros", this.state.resultados);
					//console.log("Resultado agregacoes", this.state.agregacoes);
					//console.log("tiposJurisAgregador após pesquisa ", this.state.tiposJurisAgregador);
					//console.log("tipoJurisAgregadorSelecionado", this.state.tipoJurisAgregadorSelecionado);
				});
			})
			.catch(err => {
				console.error(err);
				this.setState({
					resultados: [],
					agregacoes: [],
					tiposJurisAgregador: JSON.parse(JSON.stringify(this.state.tiposJurisSelecionados)),
					mensagemConsulta: this.determinaMensagem(err, 0),
					pesquisando: false,
				});
			});

		this.setState({
			quantidadeRegistros: (quantidadeRegistrosOriginal > quantidadeRegistros ?
				quantidadeRegistrosOriginal :
				quantidadeRegistros),
			indicePaginaAtual: indicePaginaAtual,
		});
	}

	limparCampos = () => {
		this.setState({
			...resultadoVazio,
			operadorOu: '',
			operadorE: '',
			operadorExpressaoExata: '',
			operadorNaoContem: '',
			ementa: '',
			dispositivo: '',
			processo: {
				numero: '',
				ano: '',
				digito: '',
				orgao: '5',
				tribunal: '',
				vara: ''
			},
			orgao: isCsjt ? 'CSJT' : 'TST',
			orgaosJudicantesSelecionados: [],
			ministrosSelecionados: [],
			convocadosSelecionados: [],
			classesProcessuaisSelecionadas: [],
			indicadoresSelecionados: [],
			assuntosSelecionados: [],
			tiposJurisSelecionados: JSON.parse(JSON.stringify(listaTiposJurisprudencia)),
			tiposJurisAgregador: [],
			tipoJurisAgregadorSelecionado: '',
			publicacaoInicial: '',
			publicacaoFinal: '',
			julgamentoInicial: '',
			julgamentoFinal: '',
		}, () => {
			this.handleClickHome();
		});
	}

	qtdRegistrosComDestaques(registros) {
		let regDestaques = registros.filter(
			registro => Object.keys(registro.destaques).length !== 0
		);
		//console.log("Qtd registros com destaques ", regDestaques.length);

		return regDestaques.length;
	}

	handleClickHome = () => {
		//se quiser animado: document.getElementById("root").scrollIntoView({behavior: "auto", block: "start", inline: "nearest"});
		window.scrollTo(0, 0);
	}

	ordenar = (ordenacao) => {
		this.setState({ ordenacao: ordenacao }, 
			          () => {
			              this.pesquisar(this.state.indicePaginaAtual, this.state.quantidadeRegistros);
		              }
		); 
	}

	getValorVazioParaCampoDeData = () => {
		if (isDataSuportada) {
			return '';
		} else {
			return null;
		}
	}


	/** COMPARTILHAMENTO **/
	getValorCampo = (campo, apelidoCampo) => {
		if (this.state[campo]) {
			return "&" + apelidoCampo + "=" + encodeURI(this.state[campo]);
		}
		return "";
	}

	getValorCampoNumProcesso = (campo, apelidoCampo) => {
		if (this.state.processo[campo]) {
			return "&" + apelidoCampo + "=" + escape(this.state.processo[campo]);
		}
		return "";
	}

	getValorCampoDeLista = (nomeLocalStorage, campo, campoValor, apelidoCampo) => {
		if ( this.state[campo] ) {
			if (this.state[campo].length > 0) {
				let urlFinal = "&" + apelidoCampo + "=";
				let array = this.getArrayDeObjAPartirDoArrayDeIds(nomeLocalStorage, this.state[campo])
				array.forEach(function (item, idx, array) {
					urlFinal += escape(item[campoValor]) + (idx === array.length - 1 ? "" : ",");
				});
				return urlFinal;
			}
		}
		return "";
	}

	compartilhar = () => {
		let tiposJuris = this.state.tiposJurisSelecionados.filter(a => a.checked === true)
			.map(a => a.codigo);
		var url = window.location.origin;
		if (tiposJuris.indexOf("TODOS") >= 0) {
			url += "?tipoJuris=TODOS";
		} else {
			url += "?tipoJuris=" + tiposJuris.join(",");
		}

		url += "&orgao=" + this.state.orgao

		url += this.getValorCampo("operadorE", "e");
		url += this.getValorCampo("operadorOu", "ou");
		url += this.getValorCampo("operadorNaoContem", "nao");
		url += this.getValorCampo("ementa", "ementa");
		url += this.getValorCampo("dispositivo", "disp");

		url += this.getValorCampoNumProcesso("numero", "numProc");
		url += this.getValorCampoNumProcesso("digito", "digProc");
		url += this.getValorCampoNumProcesso("ano", "anoProc");
		url += this.getValorCampoNumProcesso("tribunal", "numTribunal");
		url += this.getValorCampoNumProcesso("vara", "numVara");

		url += this.getValorCampoDeLista("orgaosJudicantes", "orgaosJudicantesSelecionados", "codigo", "orgaosJudic");
		url += this.getValorCampoDeLista("ministros", "ministrosSelecionados", "codMin", "min");
		url += this.getValorCampoDeLista("convocados", "convocadosSelecionados", "codMin", "conv");
		url += this.getValorCampoDeLista("classesProcessuais", "classesProcessuaisSelecionadas", "codFase", "classes");
		url += this.getValorCampoDeLista("indicadores", "indicadoresSelecionados", "codIndicador", "indicadores");
		if (this.state.assuntosSelecionados.length > 0)
			url += "&assuntos=" + this.state.assuntosSelecionados;

		url += this.getValorCampo("publicacaoInicial", "publicacaoInicial");
		url += this.getValorCampo("publicacaoFinal", "publicacaoFinal");
		url += this.getValorCampo("julgamentoInicial", "julgamentoInicial");
		url += this.getValorCampo("julgamentoFinal", "julgamentoFinal");

		this.setState({ urlCompartilhamento: url });
	}

	getListaLocalStorage(nome) {
		return (localStorage[nome] ?
		        JSON.parse(localStorage[nome]) :
		        []);
	}

	/** CARREGAMENTO DOS CAMPOS QUE VIEREM NA URL */
	componentDidMount() {
        var url = new URLSearchParams(window.location.search);
		

		this.setState({
			operadorE: url.get('e'),
			operadorOu: url.get('ou'),
			operadorNaoContem: url.get('nao'),
			ementa: url.get('ementa'),
			dispositivo: url.get('disp'),
			processo: {
				numero: url.get('numProc'),
				digito: url.get('digProc'),
				ano: url.get('anoProc'),
				orgao: '5',
				tribunal: url.get('numTribunal'),
				vara: url.get('numVara')
			},
			tiposJurisSelecionados: this.getTiposSelecionados(url),
			orgaosJudicantesSelecionados: this.getSelecionados(url, 'num', 'orgaosJudic'),
			ministrosSelecionados: this.getSelecionados(url, 'str', 'min'),
			convocadosSelecionados: this.getSelecionados(url, 'str', 'conv'),
			classesProcessuaisSelecionadas: this.getSelecionados(url, 'str', 'classes'),
			indicadoresSelecionados: this.getSelecionados(url, 'num', 'indicadores'),
			assuntosSelecionados: this.getSelecionados(url, 'num', 'assuntos'),
			orgao: url.get('orgao') ? url.get('orgao') : 'TST',
			publicacaoInicial: this.getDataDaUrl(url, 'publicacaoInicial'),
			publicacaoFinal: this.getDataDaUrl(url, 'publicacaoFinal'),
			julgamentoInicial: this.getDataDaUrl(url, 'julgamentoInicial'),
			julgamentoFinal: this.getDataDaUrl(url, 'julgamentoFinal'),
		}, () => {
			if ( url.get("pesquisar") ) {
				let registrosPorPagina = undefined;
				if ( url.get("registrosPorPagina") ) {
					registrosPorPagina = Number(url.get("registrosPorPagina"));
				}
				if ( url.get("indLeiaMais") && localStorage.indLeiaMais === undefined) {
					this.setState({
						indLeiaMais: eval(url.get("indLeiaMais"))
					})
				}
	
				this.pesquisar(undefined, registrosPorPagina)
			}
		})
	}

	getSelecionados = (url, tipo, param) => {
		let listaSelecionados = [];
		
		if (url.get(param)) {
			let escolhidos = url.get(param).split(",");
			if (escolhidos.length > 0) {
				for (var i = 0; i < escolhidos.length; i++) {
					listaSelecionados.push( tipo == "num" ?  parseInt(escolhidos[i]) : escolhidos[i]);
				};
			}	
		}
		
		return listaSelecionados;
    }

	getTiposSelecionados = (url) => {
		if ( url.get('tipoJuris') ) {
			var tiposEscolhidos = url.get('tipoJuris').split(",");

			if ( tiposEscolhidos.indexOf("TODOS") < 0 ) {
				listaTiposJurisprudencia.forEach ( function (element, index, array) {
					let checked = false;
					tiposEscolhidos.forEach ( function (element2, index2, array2) {
						if ( element.codigo === element2 ) {
							checked = true;
						}
					});
					element.checked = checked;
				});
			}
		}
		
		return JSON.parse(JSON.stringify(listaTiposJurisprudencia))
	}

	getHoje = () => {
		return (new Date()).toISOString().split("T")[0]
	}

	getDataDaUrl = (url, nomeCampoData) => {
		if (url.get(nomeCampoData)) {
			if ( url.get(nomeCampoData) === "hoje" ) {
				return this.getHoje();
			} else {
				return url.get(nomeCampoData);
			}
		} else {
			this.getValorVazioParaCampoDeData();
		}
	}

	render() {
		const { classes } = this.props;
		return (
			<div className={classes.root}>
				<CircularProgress className={classes.progress} style={{ position: 'fixed', top: '50%', left: '50%', display: this.state.pesquisando ? '' : 'none' }} />
				<AppBar position="sticky">
					<Toolbar>
						<img src={logo_tst} text='' alt='' style={{ marginRight: "7px", marginBottom: "16px", marginTop: "5px", height: "43px" }} />
						<Typography variant="h6" color="inherit" className={classes.grow} style={{marginLeft: "20px"}}>
							PESQUISA DE JURISPRUDÊNCIA
                        </Typography>
						<ModalAjuda />
						<IconButton onClick={this.handleClickHome} className={classes.menuButton} color="inherit" aria-label="Menu">
							<HomeIcon />
						</IconButton>
					</Toolbar>
				</AppBar>

				<div className={classes.root}>
					<form className="{classes.container}" noValidate autoComplete="off">
						<Grid container spacing={3}>
							<Grid item xs={12} sm={6} >
								<PesquisaTextual 
									operadorOu={this.state.operadorOu}
									operadorE={this.state.operadorE}
									operadorExpressaoExata={this.state.operadorExpressaoExata}
									operadorNaoContem={this.state.operadorNaoContem}
									ementa={this.state.ementa}
									dispositivo={this.state.dispositivo}
									pesquisar={this.pesquisar}
									limparCampos={this.limparCampos}
									compartilhar={this.compartilhar}
									urlCompartilhamento={this.state.urlCompartilhamento}
									atualizaValorDosCamposDePesquisaTextual={this.atualizaValorDosCamposDePesquisaTextual} />
							</Grid>
							<Grid item xs={12} sm={3} className='altura-75'>
								<TipoJuris
									tiposJurisSelecionados={this.state.tiposJurisSelecionados}
									atualizaTiposPai={this.atualizaTipos}
									atualizaOrgao={this.atualizaOrgao}
									orgao={this.state.orgao} 
								/>
							</Grid>
							<Grid item xs={12} sm={3} className='altura-75'>
								<PesquisaAvancada
									atualizaValorDosCamposDePesquisaTextual={this.atualizaValorDosCamposDePesquisaTextual}
									processo={this.state.processo}
									orgaosJudicantesSelecionados={this.state.orgaosJudicantesSelecionados}
									ministrosSelecionados={this.state.ministrosSelecionados}
									convocadosSelecionados={this.state.convocadosSelecionados}
									classesProcessuaisSelecionadas={this.state.classesProcessuaisSelecionadas}
									indicadoresSelecionados={this.state.indicadoresSelecionados}
									assuntosSelecionados={this.state.assuntosSelecionados}
									publicacaoInicial={this.state.publicacaoInicial}
									publicacaoFinal={this.state.publicacaoFinal}
									julgamentoInicial={this.state.julgamentoInicial}
									julgamentoFinal={this.state.julgamentoFinal}
								/>
							</Grid>
						</Grid>
						<Hidden smUp>
							<div id="botoesExtras">
								<Button classes={{label: classes.labelForm}} style={{margin: '10px'}} onClick={this.pesquisar.bind(this, undefined, undefined)} variant="contained" color="primary" size="large">
										<Search />
										Pesquisar
								</Button>
								<Button classes={{label: classes.labelForm}} style={{margin: '10px'}} onClick={this.limparCampos} variant="contained" color="primary" size="large">
										<Clear />
										Limpar
								</Button>
								<ModalCompartilhamento  
									tituloDialogo="Compartilhar Pesquisa"
									urlCompartilhamento={this.urlCompartilhamento}
									compartilhar={this.compartilhar}
                            	/>
							</div>
							<Divider />
						</Hidden>
					</form>
				</div>
				<main className={classes.tableRoot} id='main'  >
					{this.state.tiposJurisAgregador.length > 0 ?
						<Suspense fallback={<div>Carregando...</div>}>
							<AgregadorResultado
								tiposJurisAgregador={this.state.tiposJurisAgregador}
								value={this.state.tipoJurisAgregadorSelecionado}
								registros={this.state.resultados}
								agregacoes={this.state.agregacoes}
								page={this.state.indicePaginaAtual}
								rowsPerPage={this.state.quantidadeRegistros}
								atualizaTipoJurisAgregadorSelecionado={this.atualizaTipoJurisAgregadorSelecionado}
								ordenacao={this.state.ordenacao}
								ordenar={this.ordenar}
								mensagemConsulta={this.state.mensagemConsulta}
								apagaMensagem={this.apagaMensagem}
								qtdRegistrosComDestaques={this.qtdRegistrosComDestaques(this.state.resultados)}
								indLeiaMais={this.state.indLeiaMais}
							/>
						</Suspense>
						: null}
				</main>
			</div>
		);
	}
}

App.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(App);