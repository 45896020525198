import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

class SwitchAtivosInativos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ativos: props.ativos,
            inativos: props.inativos,
        };
    }

    componentWillReceiveProps(props) {
        this.setState({
            ativos: props.ativos,
            inativos: props.inativos,
        });
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.checked });
        this.props.atualizaValorSwitch(name, event.target.checked);
    };

    render() {
        return (
            <FormGroup row>
                <FormControlLabel
                    style={{paddingLeft: "8%"}}
                    control={
                        <Switch
                            checked={this.state.ativos}
                            onChange={this.handleChange('ativos')}
                            value="ativos"
                            color="primary"
                        />
                    }
                    label="Ativos"
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={this.state.inativos}
                            onChange={this.handleChange('inativos')}
                            value="inativos"
                            color="primary"
                        />
                    }
                    label="Inativos"
                />
            </FormGroup>
        );
    }
}

export default SwitchAtivosInativos;
