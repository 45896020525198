import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from '@material-ui/core/styles/colorManipulator';

const styles = theme => ({
    root: {
        flexGrow: 1,
        height: '100%',
        paddingTop: '15px',
    },
    input: {
        display: 'flex',
        padding: 0,
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
    },
    chip: {
        margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
        fontSize: 12,
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08,
        ),
    },
    noOptionsMessage: {
        padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        fontSize: 16,
        fontWeight: 300,
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing.unit,
        left: 0,
        right: 0,
    },
    divider: {
        height: theme.spacing.unit * 2,
    },
    button: {
        margin: theme.spacing.unit,
        textTransform: 'initial',
    },
});

function NoOptionsMessage(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}
        >
            Nenhum valor encontrado
        </Typography>
    );
}

function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />;
}

function Control(props) {
    return (
        <TextField
            fullWidth
            InputProps={{
                inputComponent,
                inputProps: {
                    className: props.selectProps.classes.input,
                    inputRef: props.innerRef,
                    children: props.children,
                    ...props.innerProps,
                },
            }}
            {...props.selectProps.textFieldProps}
        />
    );
}

function Option(props) {
    return (
        <Tooltip disableFocusListener title={props.children}>
            <MenuItem
                buttonRef={props.innerRef}
                selected={props.isFocused}
                component="div"
                style={{
                    fontWeight: props.isSelected ? 500 : 400,
                    fontSize: 12,
                }}
                {...props.innerProps}
            >
                {props.children}
            </MenuItem>
        </Tooltip>
    );
}

function Placeholder(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.placeholder}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

function ValueContainer(props) {
    return (
        <div className={props.selectProps.classes.valueContainer}>
            {props.children}
        </div>
    );
}

function MultiValue(props) {
    return (
        <Tooltip disableFocusListener title={props.children}>
            <Chip
                tabIndex={-1}
                label={props.children}
                className={classNames(props.selectProps.classes.chip, {
                    [props.selectProps.classes.chipFocused]: props.isFocused,
                })}
                onDelete={props.removeProps.onClick}
                deleteIcon={<CancelIcon {...props.removeProps} />}
            />
        </Tooltip>
    );
}

function Menu(props) {
    return (
        <Paper 
            square 
            className={props.selectProps.classes.paper} 
            {...props.innerProps}>
            <Button 
                className={props.selectProps.classes.button}
                style={{width: '90%'}}
                onClick={props.selectProps.handleChangeButton}
            >
                Incluir todas as turmas
            </Button>
            {props.children}
        </Paper>
    );
}

const components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    ValueContainer,
};

class MultiSelectOJ extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nomeExibido: props.nomeExibido,
            selectedOption: props.listaSelecionados,
        };
    }

    componentWillReceiveProps(props) {
        this.setState({
            selectedOption: props.listaSelecionados,
        });
    }

    atualizaValoresDoCampo = (campo, valor) => {
        this.props.atualizaValorDosCamposDePesquisaTextual(campo, valor);
    }

    atualizaSuggestions = (itens) => {
        return  itens.map(suggestion => ({
                    value: suggestion,
                    label: suggestion[this.props.nomeExibido],
                }));
    }

    handleChangeButton = () => {
        let re = /^T[1-8]$/;
        let itens = this.atualizaSuggestions(this.props.itens);

        // Mantém o que não é turma
        let newSelectedOption = [];
        this.state.selectedOption.map( selected => {
            if (!re.test(selected.value.sigla)) { 
                newSelectedOption.push(selected);
            }
        });

        // Busca todas as turmas
        let turmas = itens.filter(
            item => re.test(item.value.sigla)
        );
        turmas.map(
            item => {
                newSelectedOption.push(item);
            }
        )
        this.handleChange(newSelectedOption);
    }
    
    handleChange = (selectedOption) => {
        this.setState({ selectedOption });
        let lista = [];
        if (selectedOption != null) {        
            for (let i = 0; i < selectedOption.length; i++) {
                lista.push({ value: selectedOption[i].value,
                            label: selectedOption[i].label, });
            }
        }
        this.atualizaValoresDoCampo(this.props.nomeVariavel, lista);
    };
    
    render() {
        const { classes, theme } = this.props;
        const { selectedOption } = this.state;

        const selectStyles = {
            input: base => ({
                ...base,
                color: theme.palette.text.primary,
                '& input': {
                    font: 'inherit',
                },
            }),
        };
        
        const suggestions = this.atualizaSuggestions(this.props.itens);

        return (
                <Select
                    className={classes.root}
                    aria-label={this.props.descricao}
                    classes={classes}
                    styles={selectStyles}
                    textFieldProps={{
                        label: this.props.titulo,
                        InputLabelProps: {
                            shrink: true,
                        },
                    }}
                    options={suggestions}
                    handleChangeButton={this.handleChangeButton}
                    components={components}
                    value={selectedOption}
                    onChange={this.handleChange}
                    placeholder={this.props.placeholder}
                    isMulti
                >
                </Select>
        );
    }
}

MultiSelectOJ.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(MultiSelectOJ);
