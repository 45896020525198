import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select, { createFilter } from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from '@material-ui/core/styles/colorManipulator';

const styles = theme => ({
    root: {
        flexGrow: 1,
        height: '100%',
        paddingTop: '15px',
    },
    input: {
        display: 'flex',
        padding: 0,
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
    },
    chip: {
        margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
        fontSize: 12,
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08,
        ),
    },
    noOptionsMessage: {
        padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        fontSize: 16,
        fontWeight: 300,
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing.unit,
        left: 0,
        right: 0,
    },
    divider: {
        height: theme.spacing.unit * 2,
    },
});

function NoOptionsMessage(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}
        >
            Nenhum valor encontrado
        </Typography>
    );
}

function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />;
}

function Control(props) {
    return (
        <TextField
            fullWidth
            InputProps={{
                inputComponent,
                inputProps: {
                    className: props.selectProps.classes.input,
                    inputRef: props.innerRef,
                    children: props.children,
                    ...props.innerProps,
                },
            }}
            {...props.selectProps.textFieldProps}
        />
    );
}

function Option(props) {
    return (
        <Tooltip disableFocusListener title={props.data.title}>
            <MenuItem
                buttonRef={props.innerRef}
                selected={props.isFocused}
                component="div"
                style={{
                    fontWeight: props.isSelected ? 500 : 400,
                    fontSize: 12,
                }}
                {...props.innerProps}
            >
                {props.children}
            </MenuItem>
        </Tooltip>
    );
}

function Placeholder(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.placeholder}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

function ValueContainer(props) {
    return (
        <div className={props.selectProps.classes.valueContainer}>
            {props.children}
        </div>
    );
}

function MultiValue(props) {
    return (
        <Tooltip disableFocusListener title={props.children}>
            <Chip
                tabIndex={-1}
                label={props.children}
                className={classNames(props.selectProps.classes.chip, {
                    [props.selectProps.classes.chipFocused]: props.isFocused,
                })}
                onDelete={props.removeProps.onClick}
                deleteIcon={<CancelIcon {...props.removeProps} />}
            />
        </Tooltip>
    );
}

function Menu(props) {
    return (
        <Paper 
            square 
            className={props.selectProps.classes.paper} 
            {...props.innerProps}>
            {props.children}
        </Paper>
    );
}

function customFilter() {
    const filter = createFilter({ ignoreCase: true, ignoreAccents: true, trim: true });

    return function(...args) {
        const [data, ...otherArgs] = args;
        if (data.label && data.data && data.data.title) {
            return filter({ label: `${data.label} ${data.data.title}`}, ...otherArgs);
        } else {
            return filter(data, ...otherArgs);
        }
        
    }
}

const components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    ValueContainer,
};

class MultiSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nomeExibido: props.nomeExibido,
            textoParaTooltip: props.textoParaTooltip,
            selectedOption: props.listaSelecionados,
        };
    }

    componentWillReceiveProps(props) {
        this.setState({
            selectedOption: props.listaSelecionados,
        });
    }

    atualizaValoresDoCampo = (campo, valor) => {
        this.props.atualizaValorDosCamposDePesquisaTextual(campo, valor);
    }
    
    handleChange = (selectedOption) => {
        this.setState({ selectedOption });
        
        let lista = [];
        if (selectedOption != null) {
            for (let i = 0; i < selectedOption.length; i++) {
                lista.push({ value: selectedOption[i].value,
                             label: selectedOption[i].label, });
            }
        }
        this.atualizaValoresDoCampo(this.props.nomeVariavel, lista);
        console.log("lista " + this.props.nomeVariavel + " = ", lista);
    };
    
    render() {
        const { classes, theme } = this.props;
        const { selectedOption } = this.state;

        const selectStyles = {
            input: base => ({
                ...base,
                color: theme.palette.text.primary,
                '& input': {
                    font: 'inherit',
                },
            }),
        };

        const suggestions = this.props.itens.map(suggestion => ({
            value: suggestion,
            label: suggestion[this.props.nomeExibido],
            title: suggestion[this.props.textoParaTooltip ? this.props.textoParaTooltip : this.props.nomeExibido]
        }));

        return (
                <Select
                    className={classes.root}
                    aria-label={this.props.descricao}
                    classes={classes}
                    styles={selectStyles}
                    textFieldProps={{
                        label: this.props.titulo,
                        InputLabelProps: {
                            shrink: true,
                        },
                    }}
                    options={suggestions}
                    components={components}
                    value={selectedOption}
                    onChange={this.handleChange}
                    placeholder={this.props.placeholder}
                    filterOption={customFilter()}
                    isMulti
                >
                </Select>
        );
    }
}

MultiSelect.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(MultiSelect);
