  import { withStyles } from "@material-ui/core/styles";
import React from "react";

import { Card, CardContent, CardHeader, Collapse, Divider, IconButton, TextField, Typography, Paper } from "@material-ui/core";
import configuration from "config-easy";

import MultiSelect from "./MultiSelect";
import MultiSelectOJ from "./MultiSelectOJ";
import MultiSelectPlus from "./MultiSelectPlus";
import NumeroDeProcesso from "./NumeroDeProcesso";

import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/pt-br";

import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DatePicker, MuiPickersUtilsProvider} from "material-ui-pickers";
import DropdownTreeSelectMaterial from "./components/DropdownTreeSelectMaterial";

moment.locale("pt-br");

const styles = {
  menu: {
    width: 200,
  },
  label: {
  width: '100%',
  paddingTop: '0px',
  textTransform: 'initial',
  verticalAlign: 'middle',
  horizontalAlign: 'center',
  fontSize: '0.85rem',
  fontWeight: 500,
  color: '#757575'
  },
};


var isDataSuportada = true;

class PesquisaAvancada extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      top: true,
      resultados: [],
      orgaosJudicantes: [],
      ministros: [],
      convocados: [],
      classesProcessuais: [],
      indicadores: [],
      assuntos: [],
      expandido: isWidthUp("md", props.width),
    };
  }

  componentDidMount() {

    this.carregaCombo("orgaosJudicantes");
    this.carregaCombo("ministros");
    this.carregaCombo("convocados");
    this.carregaCombo("classesProcessuais");
    this.carregaCombo("indicadores");
    this.carregaCombo("assuntos");

  }

  carregaCombo = (nomeCombo, segundosParaNovaTentativa) => {

    let dataAgora = new Date();
    //Atualiza as combos a cada 1 hora na máquina do cliente
    if (localStorage[nomeCombo] !== undefined && localStorage.horaUltimaAtualizacao === dataAgora.getHours()) {
      let dados = JSON.parse(localStorage[nomeCombo]);
      this.carregaDadosComboDoLocalstorage(dados, nomeCombo);
      return;
    }

    if (segundosParaNovaTentativa === undefined) {
      segundosParaNovaTentativa = 2;
    }
    let url;

    if (nomeCombo === "orgaosJudicantes") {
      url = `${configuration.get("base_url")}/rest/orgaos-judicantes`;
    } else if (nomeCombo === "ministros") {
      url = `${configuration.get("base_url")}/rest/ministros`;
    } else if (nomeCombo === "convocados") {
      url = `${configuration.get("base_url")}/rest/convocados`;
    } else if (nomeCombo === "classesProcessuais") {
      url = `${configuration.get("base_url")}/rest/classes-processuais`;
    } else if (nomeCombo === "indicadores") {
      url = `${configuration.get("base_url")}/rest/indicadores`;
    } else if (nomeCombo === "assuntos") {
      url = `${configuration.get("base_url")}/rest/assuntos`;
    }

    let funcaoCarregaCombo = this.carregaCombo;
    let funcaoCarregaDadosCombo = this.carregaDadosCombo;

    fetch(url, {
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      funcaoCarregaDadosCombo(response, nomeCombo);
    }).catch(function (error) {
      console.log("Erro na combo: ", nomeCombo, "  Erro:", error)
      console.log("Tentando novamente... em " + (segundosParaNovaTentativa) + " segundos")

      if (segundosParaNovaTentativa <= 6) {
        segundosParaNovaTentativa++;
        setTimeout(function () {
          funcaoCarregaCombo(nomeCombo, segundosParaNovaTentativa);
        }, segundosParaNovaTentativa * 1000);
      }
    });
  }

  carregaDadosCombo = (response, nomeCombo) => {
    response.json().then((data) => {
      let its = [];
      if (nomeCombo === "indicadores") {
        data.forEach((it) => {
          its.push({
            id: it.codIndicador,
            value: it.codIndicador,
            label: it.desIndicador,
            nomeCombo: "indicadoresSelecionados",
            obj: it
          });
        });
      } else if (nomeCombo === "classesProcessuais") {
        data.forEach((it) => {
          its.push({
            id: it.codFase,
            value: it.codFase,
            label: it.codFase,
            nomeCombo: "classesProcessuaisSelecionadas",
            obj: it
          });
        });
      } else if (nomeCombo === "convocados") {
        data.forEach((it) => {
          if ( it.codMin != 'GMX')
            its.push({
              id: it.codMin,
              value: it.codMin,
              label: it.nomMin + " (" + it.codMin + ")",
              nomeCombo: "convocadosSelecionados",
              obj: it
            });
        });
      } else if (nomeCombo === "ministros") {
        data.forEach((it) => {
          if ( it.codMin != 'GMX')
            its.push({
              id: it.codMin,
              value: it.codMin,
              label: it.nomMin + " (" + it.codMin + ")",
              nomeCombo: "ministrosSelecionados",
              obj: it
            });
        });
      } else if (nomeCombo === "orgaosJudicantes") {
        data.forEach((it) => {
          its.push({
            id: it.codigo,
            value: it.codigo,
            label: it.descricao,
            nomeCombo: "orgaosJudicantesSelecionados",
            obj: it
          });
        });
      } else {
        its = data;
      }
      this.setState({
        [nomeCombo]: its,
      });
      localStorage[nomeCombo] = JSON.stringify(its);
      let dataAgora = new Date();
      localStorage.horaUltimaAtualizacao = dataAgora.getHours()
    });
  }

  carregaDadosComboDoLocalstorage = (dados, nomeCombo) => {
    this.setState({
      [nomeCombo]: dados,
    });
    this.props.atualizaLista(nomeCombo, dados);
  }

  atualizaValoresDoCampo = (campo, valor) => {
    this.setState({
      [campo]: valor,
    });
    this.props.atualizaValorDosCamposDePesquisaTextual(campo, valor);
  };

  onChangeOrgaoJudicante = (currentNode, selectedNodes) => {
    this.atualizaValorComboArvoreSelecionados(selectedNodes, this.state.orgaosJudicantes, "orgaosJudicantesSelecionados");
  };

  onChangeMinistro = (currentNode, selectedNodes) => {
    this.atualizaValorComboArvoreSelecionados(selectedNodes, this.state.ministros, "ministrosSelecionados");
  };

  onChangeConvocados = (currentNode, selectedNodes) => {
    this.atualizaValorComboArvoreSelecionados(selectedNodes, this.state.convocados, "convocadosSelecionados");
  };

  onChangeClassesProcessuais = (currentNode, selectedNodes) => {
    this.atualizaValorComboArvoreSelecionados(selectedNodes, this.state.classesProcessuais, "classesProcessuaisSelecionadas");
  };

  onChangeIndicadores = (currentNode, selectedNodes) => {
    this.atualizaValorComboArvoreSelecionados(selectedNodes, this.state.indicadores, "indicadoresSelecionados");
  };

  onChangeAssunto = (currentNode, selectedNodes) => {
    this.atualizaValorComboArvoreSelecionados(selectedNodes, this.state.assuntos, "assuntosSelecionados");
  };
  
  atualizaValorComboArvoreSelecionados = (selectedNodes, comboState, nomeCampo) => {
    var values = [];
    for (var contA = 0; contA < selectedNodes.length; contA++) {
      var nodeAtual = selectedNodes[contA];
      var nodeEncontrado = this.getNodeById(nodeAtual.id, comboState);
      this.getArrayDeValues(values, nodeEncontrado);
    }
    this.props.atualizaValorDosCamposDePesquisaTextual(
      nomeCampo,
      values
    );
  };

  getArrayDeValues = (array, node) => {
    if (array.indexOf(node.value) < 0) {
      array.push(node.value);
    }
    if ( node.children ) {
      for (var i = 0; i < node.children.length; i++) {
        var nodeFilho = node.children[i];
        array.push(nodeFilho.value);
        if (nodeFilho.children.length > 0) {
          this.getArrayDeValues(array, nodeFilho);
        }
      }
    }
  };

  getNodeById = (id, node) => {
    var reduce = [].reduce;
    function runner(result, node) {
      if (result || !node) return result;
      return (
        (node.id === id && node) ||
        runner(null, node.children) ||
        reduce.call(Object(node), runner, result)
      );
    }
    return runner(null, node);
  };

  handleChange = (name) => (date) => {
    var dataEscolhida = null;
    if (isDataSuportada) {
      dataEscolhida = date.target.value;
    } else {
      if (date) {
        dataEscolhida = date.format("YYYY-MM-DD");
      }
    }
    this.atualizaValoresDoCampo(name, dataEscolhida);
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.orgaosJudicantesSelecionados !== this.props.orgaosJudicantesSelecionados ||
      nextProps.ministrosSelecionados !== this.props.ministrosSelecionados ||
      nextProps.assuntosSelecionados !== this.props.assuntosSelecionados ||
      nextProps.convocadosSelecionados !== this.props.convocadosSelecionados ||
      nextProps.classesProcessuaisSelecionadas !== this.props.classesProcessuaisSelecionadas ||
      nextProps.indicadoresSelecionados !== this.props.indicadoresSelecionados ||
      nextState.orgaosJudicantes !== this.state.orgaosJudicantes ||
      nextState.ministros !== this.state.ministros ||
      nextState.assuntos !== this.state.assuntos ||
      nextState.convocados !== this.state.convocados ||
      nextState.classesProcessuais !== this.state.classesProcessuais ||
      nextState.indicadores !== this.state.indicadores ||
      nextProps.publicacaoInicial !== this.state.publicacaoInicial ||
      nextProps.publicacaoFinal !== this.state.publicacaoFinal ||
      nextProps.julgamentoInicial !== this.state.julgamentoInicial ||
      nextProps.julgamentoFinal !== this.state.julgamentoFinal ||
      nextState.publicacaoInicial !== this.state.publicacaoInicial ||
      nextState.publicacaoFinal !== this.state.publicacaoFinal ||
      nextState.julgamentoInicial !== this.state.julgamentoInicial ||
      nextState.julgamentoFinal !== this.state.julgamentoFinal ||
      nextState.expandido !== this.state.expandido
    );
  }

  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
  };

  isDateSupported = () => {
    var input = document.createElement("input");
    var value = "a";
    input.setAttribute("type", "date");
    input.setAttribute("value", value);
    isDataSuportada = input.value !== value;
  };

  handleExpandClick = () => {
    this.setState({
      expandido: !this.state.expandido,
    });
  };

  render() {

    const { classes } = this.props;
    this.isDateSupported();
    return (
      <Card style={{ overflow: "visible" }}  className={this.state.expandido ? 'altura-card-padrao' : 'altura-automatica'}>
        <CardHeader
          title={
            <Typography variant="h5" style={{ color: "white" }}>
              Filtros
              <IconButton onClick={this.handleExpandClick} alt="Mostrar ou esconder pesquisa avançada" aria-label='Filtros de pesquisa avançada. Mostrar ou esconder o formulário com os filtros de pesquisa avançada.'>
                {this.state.expandido ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </Typography>
          }
          disableTypography={true}
          style={{ backgroundColor: "#4f83cc", height: "32px" }}
        />

        <Divider />

        <Collapse in={this.state.expandido} timeout="auto" unmountOnExit>
          <CardContent style={{ paddingTop: "0" }}>
            <div style={{ flexDirection: "column" }}>
              <NumeroDeProcesso
                processo={this.props.processo}
                nomeVariavel="processo"
                atualizaValorDosCamposDePesquisaTextual={this.props.atualizaValorDosCamposDePesquisaTextual}
              />
              <DropdownTreeSelectMaterial
                label="Órgão Judicante"
                id="idOrgaoJudic"
                texts={{
                  label:"Órgão Judicante",
                  placeholder: "Digite para filtrar a lista abaixo",
                  noMatches:"Sem resultados",
                  labelRemove:"Remover",
                  inlineSearchPlaceholder:"Pesquisar..."
                }}
                keepTreeOnSearch
                data={this.state.orgaosJudicantes}
                onChange={this.onChangeOrgaoJudicante}
                selected={this.props.orgaosJudicantesSelecionados}
              />
              <DropdownTreeSelectMaterial
                label="Ministro (a)"
                texts={{
                  label:"Ministro (a)",
                  placeholder: "Digite para filtrar a lista abaixo",
                  noMatches:"Sem resultados",
                  labelRemove:"Remover",
                  inlineSearchPlaceholder:"Pesquisar..."
                }}
                keepTreeOnSearch
                data={this.state.ministros}
                onChange={this.onChangeMinistro}
                selected={this.props.ministrosSelecionados}
              />
              <DropdownTreeSelectMaterial
                label="Convocado (a)"
                texts={{
                  label:"Convocado (a)",
                  placeholder: "Digite para filtrar a lista abaixo",
                  noMatches:"Sem resultados",
                  labelRemove:"Remover",
                  inlineSearchPlaceholder:"Pesquisar..."
                }}
                keepTreeOnSearch
                data={this.state.convocados}
                onChange={this.onChangeConvocados}
                selected={this.props.convocadosSelecionados}
              />
              <DropdownTreeSelectMaterial
                label="Classe Processual"
                texts={{
                  label:"Classe Processual",
                  placeholder: "Digite para filtrar a lista abaixo",
                  noMatches:"Sem resultados",
                  labelRemove:"Remover",
                  inlineSearchPlaceholder:"Pesquisar..."
                }}
                keepTreeOnSearch
                data={this.state.classesProcessuais}
                onChange={this.onChangeClassesProcessuais}
                selected={this.props.classesProcessuaisSelecionadas}
              />
              <DropdownTreeSelectMaterial
                  label="Indicadores"
                  texts={{
                    label:"Indicadores",
                    placeholder: "Digite para filtrar a lista abaixo",
                    noMatches:"Sem resultados",
                    labelRemove:"Remover",
                    inlineSearchPlaceholder:"Pesquisar..."
                  }}
                  placeholder="Todos"
                data={this.state.indicadores}
                onChange={this.onChangeIndicadores}
                selected={this.props.indicadoresSelecionados}
                keepTreeOnSearch
              />
              <DropdownTreeSelectMaterial
                label="Assunto"
                data={this.state.assuntos}
                onChange={this.onChangeAssunto}
                selected={this.props.assuntosSelecionados}
                keepTreeOnSearch
                texts={{
                  label:"Assunto",
                  placeholder: "Digite para filtrar a lista abaixo",
                  noMatches:"Sem resultados",
                  labelRemove:"Remover",
                  inlineSearchPlaceholder:"Pesquisar..."
                }}
              />

              {isDataSuportada ? (
                <div>
                  <div style={{ paddingTop: "10px" }}>
                    <Paper
                        width='100%'
                        align='left'
                        className={classes.label}
                        elevation={0}
                        aria-hidden={true}
                    >
                      Publicação
                    </Paper>
                    <TextField
                      id="idDataPublicacaoInicio"
                      style={{ width:"150px" }}
                      label="Início"
                      type="date"
                      value={this.props.publicacaoInicial}
                      onChange={this.handleChange("publicacaoInicial")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{ style: { opacity: "0.54" } }}
                      inputProps={{ "aria-label": "Data de início da Publicação, digite a data ou utilize as setas para cima e para baixo para navegar pelas datas." }}
                    />
                    <TextField
                      id="idDataPublicacaoFim"
                      style={{ width: "150px", marginLeft: "20px" }}
                      label="Fim"
                      type="date"
                      value={this.props.publicacaoFinal}
                      onChange={this.handleChange("publicacaoFinal")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{ style: { opacity: "0.54" } }}
                      inputProps={{ "aria-label": "Data de fim da Publicação, digite a data ou utilize as setas para cima e para baixo para navegar pelas datas." }}
                    />
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <Paper
                      width='100%'
                      align='left'
                      className={classes.label}
                      elevation={0}
                      aria-hidden={true}
                    >
                        Data do Acórdão
                    </Paper>
                    <TextField
                      id="idDataAcordaoInicio"
                      style={{ width: "150px" }}
                      label="Início"
                      type="date"
                      value={this.props.julgamentoInicial}
                      onChange={this.handleChange("julgamentoInicial")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{ style: { opacity: "0.54" } }}
                      inputProps={{ "aria-label": "Data início do Acórdão, digite a data ou utilize as setas para cima e para baixo para navegar pelas datas." }}
                    />
                    <TextField
                      id="idDataAcordaoFim"
                      style={{ width: "150px", marginLeft: "20px" }}
                      label="Fim"
                      type="date"
                      value={this.props.julgamentoFinal}
                      onChange={this.handleChange("julgamentoFinal")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{ style: { opacity: "0.54" } }}
                      inputProps={{ "aria-label": "Data fim do Acórdão, digite a data ou utilize as setas para cima e para baixo para navegar pelas datas." }}
                    />
                  </div>
                </div>
              ) : (
                <MuiPickersUtilsProvider utils={MomentUtils} locale="pt-br">
                  <div style={{ paddingTop: "15px" }}>
                    <DatePicker
                      id="publicacaoInicial"
                      autoOk
                      style={{ width: "150px" }}
                      label="Publicação - Início"
                      aria-label="Data de publicação inicial"
                      invalidDateMessage="Data inválida"
                      placeholder="dd/mm/aaaa"
                      format="DD/MM/YYYY"
                      value={this.props.publicacaoInicial}
                      onChange={this.handleChange("publicacaoInicial")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <DatePicker
                      id="publicacaoFinal"
                      autoOk
                      style={{ width: "150px" }}
                      label="Fim"
                      aria-label="Data de publicação final"
                      invalidDateMessage="Data inválida"
                      placeholder="dd/mm/aaaa"
                      format="DD/MM/YYYY"
                      value={this.props.publicacaoFinal}
                      onChange={this.handleChange("publicacaoFinal")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div style={{ paddingTop: "15px" }}>
                    <DatePicker
                      id="julgamentoInicial"
                      autoOk
                      style={{ width: "150px" }}
                      label="Julgamento - Início"
                      invalidDateMessage="Data inválida"
                      placeholder="dd/mm/aaaa"
                      format="DD/MM/YYYY"
                      value={this.props.julgamentoInicial}
                      onChange={this.handleChange("julgamentoInicial")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <DatePicker
                      autoOk
                      id="julgamentoFinal"
                      style={{ width: "150px" }}
                      label="Fim"
                      invalidDateMessage="Data inválida"
                      placeholder="dd/mm/aaaa"
                      format="DD/MM/YYYY"
                      value={this.props.julgamentoFinal}
                      onChange={this.handleChange("julgamentoFinal")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </MuiPickersUtilsProvider>
              )}
            </div>
          </CardContent>
        </Collapse>
      </Card>
    );
  }
}

export default withWidth()(withStyles(styles)(PesquisaAvancada));